<template>
  <ServicesDescription :isFooter="false">
    <template #title>
        Описание
    </template>
    <template #body>
      <p>
        Одно из главных преимуществ ОнлайнЛоджик – подробная статистика по предоставляемым услугам и
        анализ звонков.
      </p>
      <p>
        Используйте их для анализа работы компании и отдельных сотрудников.
      </p>
      <span>
        Предоставляемые отчеты позволяют определять продуктивность работы, качество обслуживания и
        оптимизировать работу сотрудников.
        Помогают выявить сезонность спроса на Ваши товары и
        предложения. Показывают эффективность различных рекламных кампаний.
      </span>
    </template>
  </ServicesDescription>
</template>

<script>
import ServicesDescription from '@/components/shared/ServicesDescription.vue';

export default {
  components: {
    ServicesDescription
  }
};
</script>

<style></style>
